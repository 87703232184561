import { Typography } from '@mui/material'
import { useState } from 'react'
import { allAreaLevels } from '~pages/pages-behind-login/chartering/v2/constants'
import { MultiItemAutocomplete } from '.'
import { FormInput } from '../cargo-modal/add-cargo-modal'
import { FieldsGroupProps } from './../shared-fields'

export interface LocationProps extends FieldsGroupProps {
  variant: keyof FormInput
  label: string
  dependentInput?: keyof FormInput
  missingFields?: string[]
  disabled?: boolean
}

export function Area({
  updateFormInput,
  formInput,
  variant,
  label,
  missingFields,
  disabledFields,
}: LocationProps) {
  const input =
    Array.isArray(formInput[variant]) &&
    formInput[variant].every((item) => typeof item === 'string')
      ? [...formInput[variant]]
      : []

  return (
    <AreaRenderer
      input={input}
      label={label}
      hasError={missingFields?.includes(variant)}
      disabled={disabledFields?.includes(variant)}
      onChangeHandler={(newValues) =>
        updateFormInput({
          [variant]: newValues,
        })
      }
    />
  )
}

interface AreaRendererProps {
  hasError?: boolean
  input: string[]
  label: string
  onChangeHandler: (arr: string[]) => void
  disabled?: boolean
  disableInputValidation?: boolean
}

interface Option {
  label: string
  value: string
  level: string
}

export function AreaRenderer({
  hasError,
  input,
  label,
  onChangeHandler,
  disabled,
  disableInputValidation,
}: AreaRendererProps) {
  const [invalidInput, setInvalidInput] = useState(false)

  function onOptionChange(newValues: string[] | Option[]) {
    const values = normalizeValues(newValues)
    const newlyAddedAreaLabel = values.find((value) => !input.includes(value))

    if (
      !disableInputValidation &&
      newlyAddedAreaLabel &&
      options.every(
        (option) =>
          option.label.toLowerCase() !== newlyAddedAreaLabel?.toLowerCase(),
      )
    ) {
      setInvalidInput(true)
      return
    }

    setInvalidInput(false)

    onChangeHandler(values)
  }

  const inputLc = new Set(input.map((item) => item.toLowerCase()))

  const formattedOptions = allAreaLevels.map((item) => ({
    ...item,
    label: removeLevelIndicator(item.label).trim(),
    value: removeLeadingNumbers(item.value).trim(),
  }))

  const selectedValues = formattedOptions.filter((item) =>
    inputLc.has(item.label.toLowerCase()),
  )

  const options: Option[] = formattedOptions.filter(
    (item) =>
      !inputLc.has(item.label.toLowerCase()) &&
      !selectedValues.some((selected) => item.level.includes(selected.value)),
  )

  return (
    <>
      <MultiItemAutocomplete
        input={input}
        label={label}
        onChangeHandler={(e, newValues) => onOptionChange(newValues)}
        options={options}
        getOptionLabel={(option: Option | string) => {
          if (typeof option === 'string') {
            return option
          }
          return option.label
        }}
        groupBy={(option: Option) => option.level}
        hasError={hasError}
        disabled={disabled}
        persistOptionsScroll
      />
      {invalidInput && (
        <Typography variant="caption">
          To input area select one of the options
        </Typography>
      )}
    </>
  )
}

export function removeLeadingNumbers(str: string) {
  return str.replace(/^\d+\s*/, '')
}

function removeLevelIndicator(str: string) {
  return str.replace(/\(l[23]\)/gi, '')
}

function normalizeValues(values: string[] | Option[]) {
  return values
    .filter(
      (value) =>
        typeof value === 'string' ||
        (typeof value === 'object' && value !== null),
    )
    .map((value) =>
      typeof value === 'string' ? value : (value as { label: string }).label,
    )
}
